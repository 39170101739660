import React from 'react'
import { locale } from '../../../src/common/i18n'
import {
  getShortCompany,
  getFullCompany,
} from '../../../src/common/utils/getCompany'

const RevisionesCA = () => (
  <React.Fragment>
    <p>
      En <strong>{getFullCompany()}</strong> som especialistes en la cura i
      manteniment mecànic del teu cotxe. Posem a la teva disposició quatre
      <strong> Packs de Manteniment</strong> que s'adeqüen a les teves
      necessitats i a les del teu vehicle. Tots els nostres packs compten amb
      una revisió del vehicle, tant dièsel com a benzina, on realitzarem un
      manteniment que inclou en tots els casos
      <strong> canvi d'oli</strong>, anivellat de
      <strong> líquid refrigerant</strong> i anivellat de
      <strong> líquid eixugaparabrises</strong>.
    </p>
    <p>
      A més, tots els packs de manteniment realitzats als
      <strong> tallers {getFullCompany()}</strong> inclouen un complet
      <strong> Test de Seguretat</strong> que t'ajudarà a identificar possibles
      problemes i anomalies que pogués presentar el teu vehicle.
    </p>
    <p>
      No importa l'antiguitat del teu cotxe ni el seu quilometratge, a{' '}
      {getFullCompany()} disposem de personal tècnic que compta amb coneixements
      i formació especialitzada per oferir el millor servei al client en el
      manteniment del cotxe.
    </p>
    <p>
      Els <strong> Packs de Manteniment </strong> de {getShortCompany()} estan
      especialment dissenyats per adaptar-se a les necessitats de tota mena de
      conductors. Tria un dels packs <strong> Revisió Basic</strong>,
      <strong> Revisió Plus</strong>, <strong> Revisió Premium </strong> i
      <strong> Revisió Confort</strong>; i fes una
      <strong> posada a punt del teu vehicle al millor preu.</strong>
    </p>
  </React.Fragment>
)

const RevisionesES = () => (
  <React.Fragment>
    <p>
      En <strong>{getFullCompany()}</strong> somos especialistas en el cuidado y
      mantenimiento mecánico de tu coche. Ponemos a tu disposición cuatro{' '}
      <strong>Packs de Mantenimiento</strong> que se adecuan a tus necesidades y
      a las de tu vehículo. Todos nuestros packs cuentan con una revisión del
      vehículo, tanto diésel como gasolina, donde realizamos un mantenimiento
      que incluye en todos los casos
      <strong> cambio de aceite</strong>, nivelado de
      <strong> líquido refrigerante</strong> y nivelado de
      <strong> líquido limpiaparabrisas</strong>.
    </p>
    <p>
      Además, todos los packs de mantenimiento realizados en los
      <strong> talleres {getFullCompany()} </strong>
      incluyen un completo
      <strong> Test de Seguridad </strong>
      que te ayudará a identificar posibles problemas y anomalías que pudiera
      presentar tu vehículo.
    </p>
    <p>
      No importa la antigüedad de tu coche ni su kilometraje, en{' '}
      {getFullCompany()} disponemos de personal técnico que cuenta con
      conocimientos y formación especializada para ofrecer el mejor servicio al
      cliente en el mantenimiento del coche.
    </p>
    <p>
      Los
      <strong> Packs de Mantenimiento </strong>
      de {getShortCompany()} están especialmente diseñados para adaptarse a las
      necesidades de toda clase de conductores. Elije uno de los packs
      <strong>
        Revisión Basic, Revisión Plus, Revisión Premium y Revisión Confort
      </strong>
      ; y haz una
      <strong> puesta a punto de tu vehículo al mejor precio</strong>.
    </p>
  </React.Fragment>
)

const RevisionesContents = () =>
  locale === 'es' ? <RevisionesES /> : <RevisionesCA />

export default RevisionesContents
